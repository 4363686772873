<template>
    <div class="otherTraining_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <div class="content preferentialPolicies">
                <CourseTitle :title="formData.preferentialPoliciesName"> </CourseTitle>
                <div class="preferentialPolicies_div">
                    <ul class="preferentialPolicies_div_ul">
                        <li class="preferentialPolicies_div_li" v-for="(
								item, index
							) in formData.otherTrainingPreferentialPoliciesS" :key="index">
                            <div class="li_title">{{ item.preferentialPoliciesName }}</div>
                            <div class="li_text">
                                {{ item.preferentialPoliciesIntroduce }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.courseOverviewName"> </CourseTitle>
                <div class="courseOverview">
                    <ul class="flex-center">
                        <li :style="{ backgroundImage: `url(${item.courseOverviewImgUrl})` }"
                            class="courseOverview_li flex-center column"
                            v-for="(item, index) in formData.otherTrainingCourseOverviews" :key="index">
                            <div class="courseOverview_li_title">
                                {{ item.courseOverviewName }}
                            </div>
                            <div class="courseOverview_li_line"></div>
                            <div class="courseOverview_li_content">
                                {{ item.courseOverviewIntroduce }}
                            </div>
                            <div class="btn pointer" @click="service">了解更多>></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <CourseTitle :title="formData.humanResourceTrainingName"> </CourseTitle>
                <div class="humanResourceTraining flex">
                    <div class="stage_div">
                        <ul class="stage_ul">
                            <li class="stage_li flex-center pointer" :class="activeIndex == index ? 'stage_li_active' : ''"
                                v-for="(
									item, index
								) in formData.otherTrainingHumanResourceTrainings" :key="index" @click="changeBtn(item, index)">
                                <div class="num">0{{ index + 1 }}</div>
                                <div class="stage_li_content">
                                    <div class="name">{{ item.humanResourceTrainingName }}</div>
                                    <div class="text">
                                        {{ item.humanResourceTrainingIntroduce }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="stage_details">
                        <ul class="stage_details_ul flex">
                            <li class="stage_details_li flex-center column" v-for="(item, index) in humanResourceTraining"
                                :key="index">
                                <img :src="item.humanResourceTrainingImgUrl" alt="" />
                                <div class="name">{{ item.humanResourceTrainingName }}</div>
                                <div class="text">
                                    {{ item.humanResourceTrainingIntroduce }}
                                </div>
                            </li>
                        </ul>
                        <div class="btn display pointer" @click="service">咨询更多内容</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <CourseTitle :title="formData.teacherCertificationName"> </CourseTitle>
                <div class="teacherCertification flex">
                    <div class="fl">
                        <ul class="flex flex-wrap">
                            <li class="list display" v-for="(item, index) in teacherCertificationFl" :key="index">
                                {{ item.teacherCertificationName }}
                            </li>
                        </ul>
                    </div>
                    <div class="fr">
                        <ul class="fr_ul flex flex-wrap">
                            <li class="fr_li display column" v-for="(item, index) in teacherCertificationFr" :key="index">
                                <div class="title">{{ item.teacherCertificationName }}</div>
                                <div class="text">{{ item.teacherCertificationIntroduce }}</div>
                                <div class="btn display pointer" @click="service">点击咨询</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.financeCertificationName"> </CourseTitle>
            <div class="content">
                <div class="financialQualificationCertificate">
                    <ul class="Around">
                        <li v-for="(
								item, index
							) in formData.otherTrainingFinanceCertifications" :key="index">
                            <div class="title flex-center">
                                <div class="line"></div>
                                {{ item.financeCertificationName }}
                            </div>
                            <div class="text">
                                {{ item.financeCertificationIntroduce }}
                            </div>
                        </li>
                    </ul>
                    <div class="btn display pointer" @click="service">
                        免费获取考试大纲
                    </div>
                </div>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.financialTitleName"> </CourseTitle>
            <div class="content">
                <div class="financialQualificationCertificateTrainingContent">
                    <ul class="">
                        <li class="flex" v-for="(item, index) in formData.otherTrainingFinancialTitles" :key="index">
                            <div class="label display">{{ item.financialTitleName }}</div>
                            <div class="text flex-center">
                                {{ item.financialTitleIntroduce }}
                            </div>
                        </li>
                    </ul>
                    <div class="btn display pointer" @click="service">
                        免费领取备考资料包
                    </div>
                </div>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.intermediateTitleName"> </CourseTitle>
            <div class="content">
                <div class="intermediateTitle">
                    <ul class="flex">
                        <li class="flex-center column" v-for="(item, index) in formData.otherTrainingIntermediateTitles"
                            :key="index">
                            <div class="label display">{{ item.intermediateTitleName }}</div>
                            <div class="line"></div>
                            <div class="text display">
                                {{ item.intermediateTitleIntroduce }}
                            </div>
                        </li>
                    </ul>
                    <div class="btn display pointer" @click="service">
                        领取考证学习资料
                    </div>
                </div>
            </div>
        </div>
        <div class="module8">
            <div class="content">
                <div class="pertinence">
                    <CourseTitle :title="formData.gradingName" :isWhite="1">
                    </CourseTitle>
                    <ul>
                        <li class="flex-center column list" v-for="(item, index) in formData.otherTrainingGradings"
                            :key="index">
                            <div class="name">{{ item.gradingName }}</div>
                            <div class="stage">{{ item.gradingIntroduce }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="module9">
            <CourseTitle :title="formData.pickUpAuditionCourseName"> </CourseTitle>
            <FreeCharge :type="'其他培训'" :status="8"></FreeCharge>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import FreeCharge from '@/components/freeCharge'
import CourseTitle from './components/courseTitle.vue'
export default {
    components: {
        BannerImg,
        FreeCharge,
        CourseTitle,
    },
    data() {
        return {
            activeIndex: 0,
            courseOverviewList: [
                {
                    title: '人力资源培训',
                    text: '12天掌握12个典型职位实训+通关',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '教师资格证',
                    text: '考前培训班、VIP协议班，快速拿证',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '金融资格证',
                    text: '金融行业四大从业资格证书，入行必备',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '金融职称证',
                    text: '企事业部门、金融机构认可，含金量高',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
                {
                    title: '中级职称证书（计算机类）',
                    text: '入深户首选，中级职称搭配学历核准入户',
                    imgUrl: require('@/assets/img/otherTraining/bg3.png'),
                },
            ],
            formData: {},
            humanResourceTraining: [],
            teacherCertificationFl: [],
            teacherCertificationFr: [],
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.otherTrainingApi().then((res) => {
                console.log(res)
                this.formData = res.data

                this.humanResourceTraining =
                    this.formData.otherTrainingHumanResourceTrainings[0].otherTrainingHumanResourceTrainingS
                this.teacherCertificationFl =
                    this.formData.otherTrainingTeacherCertifications.slice(0, 5)
                this.teacherCertificationFr =
                    this.formData.otherTrainingTeacherCertifications.slice(5, 9)
                console.log(this.formData.otherTrainingTeacherCertifications)
                console.log(this.teacherCertificationFr)
            })
        },
        //点击人力资源培训
        changeBtn(item, index) {
            this.activeIndex = index
            this.humanResourceTraining = item.otherTrainingHumanResourceTrainingS
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.otherTraining_page {
    .module1 {
        width: 100%;
        height: 610px;
        background: url('../../assets/img/otherTraining/bg2.png') no-repeat;
        background-size: 100% 100%;

        .preferentialPolicies {
            background: url('../../assets/img/otherTraining/bg1.png') no-repeat;
            background-size: 100% 100%;
            height: 610px;

            .preferentialPolicies_div {
                .preferentialPolicies_div_ul {
                    position: relative;

                    .preferentialPolicies_div_li {
                        position: absolute;

                        .li_title {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 24px;
                        }

                        .li_text {
                            width: 224px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: 15px;
                        }
                    }

                    .preferentialPolicies_div_li:nth-child(1) {
                        top: 95px;
                        left: 190px;

                        .li_title,
                        .li_text {
                            text-align: right;
                        }
                    }

                    .preferentialPolicies_div_li:nth-child(2) {
                        top: 95px;
                        right: 190px;

                        .li_title,
                        .li_text {
                            text-align: left;
                        }
                    }

                    .preferentialPolicies_div_li:nth-child(3) {
                        top: 250px;
                        left: 190px;

                        .li_title,
                        .li_text {
                            text-align: right;
                        }
                    }

                    .preferentialPolicies_div_li:nth-child(4) {
                        top: 250px;
                        right: 190px;

                        .li_title,
                        .li_text {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .module2 {
        height: 598px;

        .courseOverview {
            margin-top: 42px;

            ul {
                height: 326px;

                .courseOverview_li:hover {
                    width: 260px;
                    height: 326px;
                    transition: all 0.6s;

                    .btn {
                        display: block;
                    }
                }

                .courseOverview_li {
                    width: 240px;
                    height: 300px;
                    background: #0053ae;
                    transition: all 0.6s;

                    .courseOverview_li_title {
                        width: 144px;
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 28px;
                        margin-top: 65px;
                    }

                    .courseOverview_li_line {
                        width: 39px;
                        height: 4px;
                        background: #ff881e;
                        margin: 24px 0;
                    }

                    .courseOverview_li_content {
                        width: 162px;
                        height: 42px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 24px;
                        text-align: center;
                    }

                    .btn {
                        width: 120px;
                        height: 45px;
                        background: #ff881e;
                        border-radius: 5px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 30px;
                        display: none;
                        line-height: 45px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module3 {
        height: 704px;
        background: url('../../assets/img/otherTraining/bg4.png') no-repeat;
        background-size: 100% 100%;

        .humanResourceTraining {
            height: 419px;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
            background: white;
            margin-top: 32px;

            .stage_div {
                width: 400px;
                height: 420px;
                background: #003c7e;
                border-radius: 5px;
                padding: 22px 24px 6px 24px;
                box-sizing: border-box;

                .stage_li_active {
                    background: #f4f5f5 !important;

                    .name,
                    .text {
                        color: #333333 !important;
                    }
                }

                .stage_li {
                    width: 352px;
                    height: 80px;
                    background: #0053ae;
                    border-radius: 5px;
                    margin-bottom: 18px;

                    .num {
                        font-size: 48px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #aabdd2;
                        line-height: 24px;
                        margin: 0 21px;
                    }

                    .stage_li_content {
                        .name {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #f4f5f5;
                            line-height: 30px;
                        }

                        .text {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #f4f5f5;
                            line-height: 30px;
                        }
                    }
                }
            }

            .stage_details {
                margin-left: 20px;

                .stage_details_ul {
                    margin-top: 55px;

                    .stage_details_li:nth-child(3) {
                        border-right: none !important;
                    }

                    .stage_details_li {
                        width: 252px;
                        height: 234px;
                        border-right: 1px solid #e3e3e3;

                        img {
                            width: 100px;
                            height: 100px;
                            margin-top: 8px;
                        }

                        .name {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 20px;
                            margin-top: 24px;
                        }

                        .text {
                            width: 192px;
                            height: 40px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: 18px;
                        }
                    }
                }

                .btn {
                    width: 248px;
                    height: 47px;
                    background: #ff881e;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    margin: 0 auto;
                    margin-top: 54px;
                }
            }
        }
    }

    .module4 {
        height: 704px;
        background: white;

        .teacherCertification {
            margin-top: 31px;
            justify-content: space-between;

            .fl {
                width: 1000px;

                ul {
                    margin-top: 81px;

                    .list:hover {
                        color: #ffffff !important;
                        background: #003c7e !important;
                    }

                    .list {
                        width: 200px;
                        height: 62px;
                        background: #f1f7fa;
                        border: 1px solid #dddddd;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #000000;
                    }

                    .list:nth-child(1) {
                        margin-left: 91px;
                        margin-bottom: 44px;
                    }

                    .list:nth-child(2) {
                        margin-left: 44px;
                        margin-bottom: 44px;
                    }

                    .list:nth-child(3) {
                        margin-left: 0px;
                    }

                    .list:nth-child(4) {
                        margin-left: 81px;
                    }

                    .list:nth-child(5) {
                        margin-left: 133px;
                        margin-top: 31px;
                    }
                }
            }

            .fr {
                .fr_ul {
                    .fr_li:nth-child(2) {
                        margin-right: 0 !important;
                    }

                    .fr_li:nth-child(4) {
                        margin-right: 0 !important;
                    }

                    .fr_li {
                        width: 200px;
                        height: 200px;
                        background: #003c7e;
                        border: 1px solid #aabdd2;
                        border-radius: 50%;
                        margin-right: 58px;
                        margin-bottom: 40px;

                        .title {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #f4f5f5;
                            line-height: 24px;
                        }

                        .text {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #f4f5f5;
                            line-height: 24px;
                            margin: 16px 0;
                        }

                        .btn {
                            width: 120px;
                            height: 36px;
                            background: #ff881e;
                            border-radius: 5px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .module5 {
        height: 640px;
        background: url('../../assets/img/otherTraining/bg5.png') no-repeat;
        background-size: 100% 100%;

        .financialQualificationCertificate {
            margin-top: 30px;

            ul {
                li {
                    width: 282px;
                    height: 285px;
                    background: url('../../assets/img/otherTraining/bg6.png') no-repeat;
                    background-size: 100% 100%;

                    .line {
                        width: 4px;
                        height: 21px;
                        background: rgba(204, 223, 233, 0.5);
                        border-radius: 2px;
                        margin-right: 20px;
                    }

                    .title {
                        height: 60px;
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        padding-left: 41px;
                        box-sizing: border-box;
                    }

                    .text {
                        width: 209px;
                        height: 106px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 30px;
                        text-align: center;
                        margin-top: 30px;
                        margin-left: 34px;
                        box-sizing: border-box;
                    }
                }
            }

            .btn {
                width: 428px;
                height: 48px;
                background: #ff881e;
                border-radius: 5px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin: 0 auto;
                margin-top: 50px;
            }
        }
    }

    .module6 {
        height: 850px;
        background: white;

        .financialQualificationCertificateTrainingContent {
            margin-top: 31px;

            ul {
                border: 1px solid #c4d1d8;
                border-right: none;
                border-bottom: none;

                li {
                    height: 70px;
                    border-bottom: 1px solid #c4d1d8;

                    .label {
                        width: 307px;
                        height: 70px;
                        border-right: 1px solid #c4d1d8;
                        background: #ebf6fc;
                        font-weight: bold;
                    }

                    .text {
                        width: 893px;
                        height: 70px;
                        background: #f1f7fa;
                        border-right: 1px solid #c4d1d8;
                        padding: 0 44px;
                        box-sizing: border-box;
                    }
                }
            }

            .btn {
                width: 428px;
                height: 48px;
                background: #ff881e;
                border-radius: 5px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin: 0 auto;
                margin-top: 50px;
            }
        }
    }

    .module7 {
        height: 650px;
        background: url('../../assets/img/otherTraining/bg7.png') no-repeat;
        background-size: 100% 100%;

        .intermediateTitle {
            margin-top: 31px;

            ul {
                li:nth-child(2) {
                    background: url('../../assets/img/otherTraining/bg9.png') no-repeat;
                    background-size: 100% 100%;
                }

                li:nth-child(4) {
                    background: url('../../assets/img/otherTraining/bg9.png') no-repeat;
                    background-size: 100% 100%;
                }

                li {
                    width: 240px;
                    height: 270px;
                    background: url('../../assets/img/otherTraining/bg8.png') no-repeat;
                    background-size: 100% 100%;
                    margin-left: -3px;

                    .label {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 60px;
                    }

                    .line {
                        width: 168px;
                        height: 1px;
                        background: #dddddd;
                        margin: 18px 0;
                    }

                    .text {
                        width: 177px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        text-align: center;
                    }
                }
            }

            .btn {
                width: 428px;
                height: 48px;
                background: #ff881e;
                border-radius: 5px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin: 0 auto;
                margin-top: 50px;
            }
        }
    }

    .module8 {
        height: 622px;
        background: url('../../assets/img/otherTraining/bg10.png') no-repeat;
        background-size: 100% 100%;

        .pertinence {
            height: 622px;
            background: url('../../assets/img/otherTraining/bg11.png') no-repeat;
            background-size: 100% 100%;

            ul {
                position: relative;

                li {
                    width: 270px;
                    position: absolute;

                    .name {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 18px;
                    }

                    .stage {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 24px;
                        margin-top: 95px;
                    }
                }

                li:nth-child(1) {
                    top: 225px;
                    left: 16px;
                }

                li:nth-child(2) {
                    top: 182px;
                    left: 310px;
                }

                li:nth-child(3) {
                    top: 144px;
                    left: 596px;
                }

                li:nth-child(4) {
                    top: 102px;
                    left: 885px;
                }
            }
        }
    }

    .module9 {
        background: white;
    }
}</style>